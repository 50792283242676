import $ from 'jquery';

function nextStep() {

    $('.js-next-step').on('click', function(e) {
        e.preventDefault();

        $(this).hide(function() {
            $(this).next().show();
        });
        $(this).parents('form').find('.hidden-step').removeClass('hidden-step');
        $(this).parents('form').find('.hidden').removeClass('hidden');
    });

}

export {nextStep};