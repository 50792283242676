import $ from 'jquery';

function openMainMenu() {

    $('.js-open-main-menu').on('click', function(e) {
        e.preventDefault();

        $('.c-header__nav-wrapper').toggleClass('active');
        if($('.c-header__nav-wrapper').hasClass('active')) $('.c-main-menu__mobile').html('<i class="fal fa-times"></i>');
        else $('.c-main-menu__mobile').html('<i class="fal fa-bars"></i>');

    });

}

export {openMainMenu};