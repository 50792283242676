import $ from 'jquery';
import slick from 'slick-carousel';

function sliderServices() {

    if ($('.js-slider-services').length) {

        var slider = $('.js-slider-services').slick({
            autoplay: true,
            arrows: false,
            fade: true,
            cssEase: 'linear',
            pauseOnHover: true
        });

        $('.js-slider-services').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            $('.c-slider-services__nav a').removeClass('active');
            $('.c-slider-services__nav a[data-goto=' + nextSlide + ']').addClass('active');
            slider.slick('slickGoTo', nextSlide);
        });

        $('.c-slider-services__nav a').on('click', function(e) {
            e.preventDefault();

            var goto = $(this).data('goto');

            $('.c-slider-services__nav a').removeClass('active');
            $(this).addClass('active');
            slider.slick('slickGoTo', goto);
        });

    }

}

export {sliderServices};