import $ from 'jquery';

function searcherTab() {

    $('.js-searcher-tab').on('click', function(e) {
        e.preventDefault();

        var $searcher = $('.c-searcher'),
            activeTab = $(this).data('tab');

        $searcher.find('.js-searcher-tab').removeClass('active');
        $searcher.find('.c-searcher__tab').removeClass('active');

        $(this).addClass('active');
        $searcher.find('.c-searcher__tab[data-tab=' + activeTab + ']').addClass('active');

    });

}

export {searcherTab};