import $ from 'jquery';
import DataTable from 'datatables.net';

function avionesList() {

    var avionesListTable;

    $(document).ready(function() {
        avionesListTable = $('.c-aviones-list__table').DataTable({
            ordering: false,
            pageLength: -1,
            lengthMenu: [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            language: {
                paginate: {
                    next: '<i class="fa fa-chevron-right"></i>',
                    previous: '<i class="fa fa-chevron-left"></i>'
                }
            }
        });
    });

    $('.js-search-aviones-list').on('keyup', function () {
        avionesListTable.search(this.value).draw();
    });

}

export {avionesList};