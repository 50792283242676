import $ from 'jquery';

function openDestino() {

    $('.js-open-destino').on('click', function(e) {
        e.preventDefault();

        $(this).find('i').toggleClass('fa-chevron-down fa-chevron-up');
        $(this).parents('.c-destinos__box').find('.c-destinos__box--hidden').toggleClass('active');

    });

}

export {openDestino};