import $ from 'jquery';

function goTop() {

    $('.js-go-top').on('click', function(e) {
        e.preventDefault();

        $('html, body').animate({
            scrollTop: 0
        }, 'slow');
    });

}

export {goTop};