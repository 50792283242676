import $ from 'jquery';

function openMenuExtra() {

    $('.js-open-menu-extra').on('click', function(e) {
        e.preventDefault();

        $('.c-header.fixed').toggleClass('active');

    });

}

export {openMenuExtra};