import $ from 'jquery';

function openFaq() {

    $('.js-open-faq').on('click', function(e) {
        e.preventDefault();

        $(this).find('i').toggleClass('fa-chevron-down fa-chevron-up');
        $(this).parent().find('.c-faq__text').toggleClass('active');

    });

}

export {openFaq};