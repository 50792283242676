import { goTop } from 'modules/goTop';
import { sliderServices } from 'modules/sliderServices';
import { searcherTab } from 'modules/searcherTab';
import { openMenuExtra } from 'modules/openMenuExtra';
import { openSubmenu } from 'modules/openSubmenu';
import { openMainMenu } from 'modules/openMainMenu';
import { openFaq } from 'modules/openFaq';
import { openDestino } from './modules/openDestino';
import { avionesList } from './modules/avionesList';
import { nextStep } from './modules/nextStep';
import { svg } from './modules/svg';

goTop();
sliderServices();
searcherTab();
openMenuExtra();
openSubmenu();
openMainMenu();
openFaq();
openDestino();
avionesList();
nextStep();
svg();