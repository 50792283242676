import $ from 'jquery';

function openSubmenu() {

    $('.js-open-submenu').on('click', function(e) {
        e.preventDefault();

        $(this).toggleClass('active');
        $(this).next('.c-main-menu__sub').toggleClass('active');

    });

}

export {openSubmenu};